'use client';
import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { LinkType } from 'onair_frontend-lib';
import Link from 'next/link';

import Icon from './Icon';
import { AVAILABLE_LANGUAGES } from './constants';

import type { FC } from 'react';
import type { LinkBase } from '@/types/link';
import { usePathname } from 'next/navigation';
import { unstable_noStore as noStore } from 'next/cache';

export type LinkInternalProps = Omit<LinkBase, 'text'> & {
    icon?: string;
    to: string;
    className?: string;
    children?: ReactNode;
    innerRef?: React.RefObject<HTMLAnchorElement>;
    onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>;
    onFocus?: React.FocusEventHandler<HTMLAnchorElement>;
    onBlur?: React.FocusEventHandler<HTMLAnchorElement>;
    onClick?: () => void;
    ariaLabel?: string;
    state?: {};
    tabIndex?: number;
    /** Property to force focus */
    isFocused?: boolean;
};

const getIsLinkActive = (params: { href: string }): boolean => {
    const { href } = params;
    const pathname = usePathname();

    const isCurrent = pathname === href;

    if (isCurrent) {
        return true;
    }

    if (!href || !pathname) return false;

    const splittedHref = href.split('/');
    const splittedCurrentRoute = pathname.split('/');

    return (
        // this will return true for example for:
        // currentRoute ->  /my-place/profile-settings/change-email
        // href         ->  /my-place/profile-settings
        splittedCurrentRoute?.[1] === 'my-place' &&
        splittedHref?.[1] === 'my-place' &&
        splittedHref?.[2] === splittedCurrentRoute?.[2]
    );
};

// We do not want trailing slashes, make sure they're removed in case they're present
// if the link is just "/" then do return the "/" - this is to go back to the root
export const getCleanLink = (to: string, defaultLocale: string) => {
    const pathname = usePathname();
    if (!pathname) return to;

    const localeFromUrl = pathname.split('/').find(Boolean);
    const localeToUse =
        localeFromUrl && AVAILABLE_LANGUAGES.includes(localeFromUrl)
            ? localeFromUrl
            : defaultLocale!;

    const isDefaultLocale = localeToUse === defaultLocale;
    const cleanLink = to.endsWith('/') && to !== '/' ? to.slice(0, -1) : to;
    if (!to.includes(localeToUse) && !isDefaultLocale) {
        return `/${localeToUse}${cleanLink}`;
    }
    return cleanLink;
};

/**
 * Please keep in mind: this is for internal links only!
 * Link uses Reach Router under the hood and does not support external links.
 */
const LinkInternal: FC<LinkInternalProps> = ({
    to,
    type = LinkType.LINK_PRIMARY,
    className,
    children,
    icon,
    innerRef,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    onClick,
    ariaLabel,
    state,
    tabIndex,
    isFocused,
}) => {
    const isActive =
        isFocused ??
        getIsLinkActive({
            href: to,
        });
    noStore();
    const defaultLocale = process.env.DEFAULT_LOCALE!;

    return (
        <Link
            prefetch={false}
            className={classNames('link', type, className, {
                'link--active': isActive,
            })}
            href={getCleanLink(to, defaultLocale)}
            onMouseOver={onMouseOver}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            aria-label={ariaLabel}
            tabIndex={tabIndex}
            ref={innerRef}
        >
            {icon && <Icon icon={icon} className="me-1" />}
            {children}
        </Link>
    );
};

export default LinkInternal;
